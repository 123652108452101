import tableVariables from "@/configs/tableVariables";
import { roundDecimals } from "@/mixins/utilFunctions";

export const getTableData = (items, headers, tableName) => {
  return items.map((obj) => {
    const calculatedData = {};
    headers.forEach((header) => {
      if (header.formula) {
        try {
          const regex = /\[\[\s*(.*?)\s*\]\]/g;
          calculatedData[header.value] = header.formula.replace(regex, (_, formulaContent) => {
            const parsedContent = parseFormula(formulaContent.trim(), tableName);
            if (parsedContent.includes("ERROR")) return `ERROR: ${parsedContent}`;

            const result = evaluateFormula(parsedContent, obj);
            return result === "Ei muodostettavissa" || result === "Virhe kaavassa"
              ? result
              : formatResult(result, header.rounding);
          });
        } catch (err) {
          calculatedData[header.value] = "Virhe laskennassa";
        }
      } else {
        const variableKey = Object.keys(tableVariables[tableName]).find(
          (key) => tableVariables[tableName][key] === header.text
        );
        if (variableKey) {
          calculatedData[header.value] = formatResult(obj[variableKey], header.rounding);
        }
      }
    });
    return { ...obj, ...calculatedData };
  });
};

export const parseFormula = (formula, mapName) => {
  const regex = /{{\s*([^}]+)\s*}}/g;
  try {
    return formula.replace(regex, (_, variableName) => {
      const key = Object.keys(tableVariables[mapName]).find(
        (key) => tableVariables[mapName][key] === variableName.trim()
      );
      if (!key) {
        return `ERROR '${variableName}' ei löytynyt.`;
      }
      return `obj.${key}`;
    });
  } catch (err) {
    return null;
  }
};

export const formatResult = (value, rounding = 2) => {
  if (value === "Ei muodostettavissa" || value === "Virhe kaavassa") return value;
  if (typeof value === "number" && rounding !== null) value = roundDecimals(value, rounding);
  return `${value}`;
};

export const evaluateFormula = (parsedFormula, obj) => {
  try {
    const fn = new Function("obj", `return ${parsedFormula}`);
    const result = fn(obj);

    if (result === undefined || result === null || isNaN(result) || !isFinite(result)) {
      return "Ei muodostettavissa";
    }

    return result;
  } catch (err) {
    return "Virhe kaavassa";
  }
};
